import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div class="d-flex justify-content-center align-items-center vh-100">
        <div>Under Maintenance</div>
      </div>
    </div>
  );
}

export default App;
